export default class AchievementService {
  getStorageAchievementsDia = () => {
    const keys = Object.keys(localStorage);
    const achievements = keys
      .filter((key) => key.includes("achievements"))
      .reduce((obj, key) => {
        obj[key] = JSON.parse(localStorage.getItem(key));
        return obj;
      }, {});
    //pegar o primeiro achievement
    const firstAchievement = Object.values(achievements)[0];
    //pegar no array do typeAchievement 'DIA' o primeiro achievement
    const firstAchievementDia = firstAchievement?.find(
      (achievement) => achievement.typeAchievement === "DIA",
    );
    return firstAchievementDia;
  };

  deleteStorageAchievements = () => {
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key.includes("achievements")) {
        localStorage.removeItem(key);
      }
    });
  };
}
