import React from "react";
import { useStore, useSelector } from "react-redux";
import packageInfo from "../../../package.json";
import CampanhaService from "../../services/CampanhaService";
import Image from "../campanha/Image";
import styled from "styled-components";

function Footer() {
  const i18n = useStore().getState().i18n;
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  const logoCentroFooter = campanha?.logoRight ?? "";
  const logoCentroFooterDesktop = campanha?.topRightDesktop ?? "";
  const hasUsuarioLogado = useSelector((state) => state.usuario);
  /** a logo so aparece nas paginas em que o usuario não está logado */
  const shouldShowLogo =
    (!hasUsuarioLogado && isDesktopMode && logoCentroFooterDesktop) ||
    (!hasUsuarioLogado && !isDesktopMode && logoCentroFooter);

  const renderVersionInfo = () => (
    <RodapeVersionInfo>{`v. ${packageInfo?.version}`}</RodapeVersionInfo>
  );

  const renderLogo = () => (
    <Rodape className="weex__container">
      <Image
        className="campanha_logosTopo__centro__footer"
        src={logoCentroFooter}
        alt={i18n.message(
          "alt.logo.secundario.footer.mobile",
          "Logo secundário da empresa",
        )}
        classNameDesktop="campanha_logosTopo__centro__footer"
        srcDesktop={logoCentroFooterDesktop}
        altDesktop={i18n.message(
          "alt.logo.secundario.footer.desktop",
          "Logo secundário da empresa",
        )}
      />
    </Rodape>
  );
  return shouldShowLogo ? renderLogo() : renderVersionInfo();
}

export const Rodape = styled.footer`
  padding-top: 0px;
  text-align: center;

  img {
    height: auto;
    max-width: 100%;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;

export const RodapeVersionInfo = styled.span`
  text-align: right !important;
  margin-top: auto;
`;

export default Footer;
