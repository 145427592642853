/* eslint-disable max-len */
import instance from "./Api";
import UsuarioService from "../services/UsuarioService";

export default class AcerteTempoService {
  usuarioService = new UsuarioService();
  usuarioCorrente = this.usuarioService.usuarioCorrente();
  codigoParticipante = this.usuarioService?.getCodigoParticipante();
  CHAVE_ACHIEVEMENTS = "achievements";

  static requestResponderQuestao = false;

  responderQuestaoHC(body, callback) {
    if (AcerteTempoService.requestResponderQuestao) {
      return new Promise(
        (resolve) => {},
        (reject) => {},
      );
    }

    AcerteTempoService.requestResponderQuestao = true;
    const tempoAtualUTC = Date.now();

    const headers = {
      "Content-Type": "application/json",
      "WEEX-TIME": `${tempoAtualUTC}`,
    };

    return instance
      .post(this.host() + "/api/v1/campaign/question/answer", body, { headers })
      .then((response) => {
        if (response.data.achievements?.length > 0) {
          localStorage.setItem(
            this.CHAVE_ACHIEVEMENTS + "-acerteTempo",
            JSON.stringify(response.data.achievements),
          );
        }
        return response.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        AcerteTempoService.requestResponderQuestao = false;
      });
  }

  setIndiceLocalStorage(indice, uuid, origem) {
    window.localStorage.setItem(
      `acerteTempo-origem-${uuid}-${this.codigoParticipante}`,
      JSON.stringify(origem),
    );
    window.localStorage.setItem(
      `acerteTempo-parou-em-${uuid}-${this.codigoParticipante}`,
      JSON.stringify(indice),
    );
  }

  getIndiceLocalStorage(uuid) {
    const response = window.localStorage.getItem(
      `acerteTempo-parou-em-${uuid}-${this.codigoParticipante}`,
    );
    if (response === null) {
      return null;
    }
    return JSON.parse(response);
  }

  getOrigemLocalStorage(uuid) {
    const response = window.localStorage.getItem(
      `acerteTempo-origem-${uuid}-${this.codigoParticipante}`,
    );
    if (response === null) {
      return null;
    }
    return JSON.parse(response);
  }

  removeIndiceLocalStorage(uuid) {
    window.localStorage.removeItem(
      `acerteTempo-origem-${uuid}-${this.codigoParticipante}`,
    );
    window.localStorage.removeItem(
      `acerteTempo-parou-em-${uuid}-${this.codigoParticipante}`,
    );
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
