/* eslint-disable react-hooks/exhaustive-deps */
import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AgendaService from "../../services/AgendaService";
import CampanhaService from "../../services/CampanhaService";
import TemaService from "../../services/TemaService";
import BotaoAsALink from "../botaoAsALink/BotaoAsALink";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import { MissaoCumpridaFeedbackModalStyle } from "./StyleMissaoCumpridaFeedback";
import { WeexModalDialog } from "./WeexModalDialog";
import achievementServie from "../../services/Achievements";

/**
 * @deprecated - componente será reformulado para se adequar ao novo design do weex 3.0
 */
function MissaoCumpridaModal() {
  const i18n = useStore().getState().i18n;
  const agendaService = new AgendaService();
  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService?.campanhaCorrente();
  const navigate = useNavigate();
  const location = useLocation();
  const { dinamicaPresencial, nomeDia, codigo } = location.state || {};
  let { codigoAgenda, codigoAtividade, atividade } = useParams();
  const [nextActivity, setNextActivity] = useState(null);
  const [processando, setProcessando] = useState(false);
  const [informacoes, setInformacoes] = useState({
    textoPontuacao: "",
    trofeu: "",
  });
  const dispatch = useDispatch();
  const dialogRef = useRef(null);

  let campaignType;
  if (campanhaCorrente) {
    campaignType = campanhaCorrente?.campaignType;
  }

  const [hasAchievements, setAchievement] = useState(null);

  //setar o valor de hasAchievements
  useEffect(() => {
    if (hasAchievements === null) {
      const achievement = new achievementServie().getStorageAchievementsDia();
      setAchievement(achievement);
    }
  }, []);

  function textScore(info) {
    let pontosObtidos = 0;
    if (location && location.state && location.state.earnedPoints) {
      pontosObtidos = location.state.earnedPoints;
    }
    if (campaignType !== "Regular" && pontosObtidos > 0) {
      info.textoPontuacao = (
        <div className="pontuacao">
          <h1>
            {" "}
            {i18n.message("missaoCumprida.pontuacao.perguntas.de", "+")}{" "}
            {pontosObtidos}{" "}
            {pontosObtidos === 1
              ? i18n.message("geral.pt", "PT")
              : i18n.message("geral.pts", "PTS")}
          </h1>
        </div>
      );
    }
  }

  useEffect(() => {
    dispatch({ type: "mainBackgroundColor", payload: "#ffffff" });
    let temaService = new TemaService(campanhaCorrente);
    temaService.aplicarTema("MISSAO");
    if (atividade === "FEEDBACK") {
      let info = {};
      textScore(info);
      setInformacoes(info);
    } else if (!processando) {
      setProcessando(true);
      agendaService.agendaCorrente((erro, agenda) => {
        if (agenda) {
          lottie.loadAnimation({
            container: document.getElementById(
              "missao-cumprida-container-confetes",
            ),
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "/confete.json",
          });
          const dia = agenda.schedule.find(
            (dia) => dia.scheduleCode === codigoAgenda,
          );
          if (dia) {
            const atividadeFinalizada = dia.activities.find(
              (atividade) => atividade.uuid === codigoAtividade,
            );
            let info = {};
            if (atividade === "FACT_OR_FAKE") {
              const acertos =
                location?.state?.questoesRespondidasCorretamente || 0;
              const pontosObtidos = location?.state?.earnedPoints || 0;
              const perguntas = atividadeFinalizada.numeroQuestoes;

              info.textoPontuacao = (
                <div className="pontuacao">
                  {campaignType !== "Regular" &&
                  pontosObtidos &&
                  pontosObtidos > 0 ? (
                    <>
                      <h1>
                        {" "}
                        {i18n.message(
                          "missaoCumprida.pontuacao.perguntas.de",
                          "+",
                        )}{" "}
                        {pontosObtidos}{" "}
                        {pontosObtidos === 1
                          ? i18n.message("geral.pt", "PT")
                          : i18n.message("geral.pts", "PTS")}
                      </h1>
                      <br></br>
                    </>
                  ) : (
                    ""
                  )}
                  <h4>
                    {" "}
                    {i18n.message(
                      "missaoCumprida.pontuacao.perguntas.inicio",
                      "Você acertou",
                    )}{" "}
                    <span>
                      {acertos}{" "}
                      {i18n.message(
                        "missaoCumprida.pontuacao.perguntas.de",
                        "de",
                      )}{" "}
                      {perguntas} (
                      {parseInt(100 * (acertos / perguntas).toFixed(2))}%)
                    </span>{" "}
                    {i18n.message(
                      "missaoCumprida.pontuacao.perguntas.final",
                      "das perguntas!",
                    )}
                  </h4>
                </div>
              );
            } else {
              textScore(info);
            }
            if (atividadeFinalizada?.nextActivity) {
              setNextActivity({
                nextActivity: atividadeFinalizada.nextActivity,
                alreadyExecuted: atividadeFinalizada.nextActivityExecuted,
              });
            }
            info.trofeu = atividadeFinalizada.trophyEnabled;
            setInformacoes(info);
          }
        }
        if (erro) {
          navigate("/");
        }
      });
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proximaAtividade = () => {
    navigate("/atividades", {
      state: {
        nextActivity: nextActivity.nextActivity,
        alreadyExecuted: nextActivity?.alreadyExecuted,
      },
    });
  };

  function voltar() {
    navigate("/atividades");
  }

  const gameRanking = () => {
    navigate("/gameRankings");
  };

  const presencialAvaliar = () => {
    navigate(`/presencialAvaliar/${codigo}/${codigoAtividade}`, {
      state: {
        dinamicaPresencial,
        nomeDia,
      },
    });
  };

  const botaoRedirect = (chaveInternacionalizacao, msgDefault, navegate) => {
    return (
      <div className="modal-card-foot-button-desktop missao-cumprida">
        <div className="modal-button-missaocomprida">
          <PrimarioBtn
            nome={i18n.message(chaveInternacionalizacao, msgDefault)}
            funcao={navegate}
            classeComplementar="weex-botao-confirmacao__texto-comprido__missao-cumprida mb-4"
            ariaStatusMessage={i18n.message(
              "aria.status.missaocompridamodal.botaomissaocumprida",
              "Botão missao cumprida clicado.",
            )}
          />
        </div>
      </div>
    );
  };

  const handlerRedirectAtividade = () => {
    if (atividade === "GAME") {
      if (new CampanhaService().campanhaCorrente().showRankingGame) {
        dispatch({ type: "game", payload: codigoAtividade });
        return botaoRedirect(
          "missaoCumprida.game.ranking",
          "IR PARA O RANKING DO GAME",
          gameRanking,
        );
      }
    } else if (atividade === "PRESENCIAL") {
      return botaoRedirect(
        "missaoCumprida.presencial.avaliar",
        "BÔNUS: AVALIAR ATIVIDADE",
        presencialAvaliar,
      );
    } else if (nextActivity !== null && !hasAchievements) {
      return botaoRedirect(
        "missaoCumprida.proximaAtividade",
        "PRÓXIMA ATIVIDADE",
        proximaAtividade,
      );
    } else {
      return botaoRedirect("missaoCumprida.trofeus", "VOLTAR", voltar);
    }
  };

  return (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={voltar}>
      <div className={"modal is-active"} style={{ zIndex: "102" }}>
        <div className="modal-background"></div>
        <div className="missaoCumprida__container">
          <header className="modal-card-head weex-modal-card-header">
            <button
              onClick={voltar}
              className="close-btn"
              aria-label={i18n.message(
                "aria.label.fechar.modal",
                "Fecha a janela e volta para a página atividades.",
              )}
              style={{ zIndex: "1003" }}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "ícone em formato de um X indicando o encerramento de uma ação.",
                )}
              />
            </button>
          </header>
          <MissaoCumpridaFeedbackModalStyle>
            <div
              id="missao-cumprida-container-confetes"
              className="missao-cumprida-container-confetes"
            ></div>
            {informacoes.trofeu ? (
              <div>
                <p className="modal-card-title wex-modal-card-img">
                  <div>
                    <img
                      src="/new_obrigado.svg"
                      alt={
                        i18n.message("alt.missaoCumprida.trofeu", "Troféu ") +
                        atividade
                      }
                      className="weex__image is-2by1"
                    ></img>
                  </div>
                </p>
              </div>
            ) : (
              ""
            )}
            <div>
              <h1 className="missao-cumprida__title">
                {i18n.message("missaoCumprida.fim", "PARABÉNS!")}
              </h1>
              <h2 className="missao-cumprida__subtitle">
                {i18n.message(
                  "missaoCumprida.parabens",
                  "Você concluiu a atividade:",
                )}
              </h2>
              <h3 className="missao-cumprida__subtitle missao-cumprida__subtitle__nameActivity">
                {atividade === "VIDEO"
                  ? i18n.message("dinamica.video.nome", "Vídeo")
                  : atividade === "FACT_OR_FAKE"
                    ? i18n.message("dinamica.fatofake.nome", "Fato ou Fake?")
                    : atividade === "NEW_HABIT"
                      ? i18n.message(
                          "dinamica.novoshabitos.nome",
                          "Novos Hábitos",
                        )
                      : atividade}
              </h3>
            </div>
            {informacoes.textoPontuacao && (
              <div className="pontuacao-container">
                {informacoes.textoPontuacao}
              </div>
            )}
            <div
              className="weex-botao-confirmacao__texto-comprido__missao-cumprida"
              style={{
                marginTop: atividade === "FACT_OR_FAKE" ? "3vh" : "35px",
                marginBottom: "20px",
              }}
            >
              {handlerRedirectAtividade()}
            </div>
            {!hasAchievements && (
              <BotaoAsALink
                nome={i18n.message("missaoCumprida.repetir", "Voltar")}
                ariaLabelMsg={i18n.message(
                  "aria.label.geral.mensagem.voltar.atividades",
                  "Volta para a página de atividades.",
                )}
                funcao={voltar}
                classeComplementar="missaoCumprida__voltar--tamanho weex-botao-confirmacao__texto-comprido__missao-cumprida"
              />
            )}
          </MissaoCumpridaFeedbackModalStyle>
        </div>
      </div>
    </WeexModalDialog>
  );
}

export default MissaoCumpridaModal;
