import React, { useEffect, useState } from "react";
import { useStore, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InteressesDesktop from "../../comps/interesse/desktop/InteressesDesktop";
import InteressesMobile from "../../comps/interesse/mobile/InteressesMobile";
import AgendaService from "../../services/AgendaService";
import CampanhaService from "../../services/CampanhaService";
import TemaService from "../../services/TemaService";
import WeexModal from "../../comps/weexModais/WeexModal";

function Interesses() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campanhaService = new CampanhaService();
  const agendaService = new AgendaService();
  const [campanha] = useState(campanhaService.campanhaCorrente());
  const [mandatoryInterestParticipant, setMandatoryInterestParticipant] =
    useState(campanha.numberOfRequiredInterests);
  const requiredInterests = campanha?.requiredInterests;
  const i18n = useStore().getState().i18n;
  const isDesktop = useStore().getState().isDesktopMode;
  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });

  useEffect(() => {
    dispatch({ type: "mainBackgroundColor", payload: "#ffffff" });

    agendaService.agendaCorrente(() => {
      if (agendaService?.obterAgendaStorage()?.pickedInterests) {
        setErro({
          isWrong: true,
          titulo: i18n.message(
            "interesse.erro.aoAdicionarInteresse.titulo",
            "Não é possivel editar interesse",
          ),
          mensagem: i18n.message(
            "interesse.erro.edicaoDeInteresses.mensagem",
            "Não é possível editar seus interesses após salvá-los.",
          ),
          voltar: true,
        });
      }
    });

    const sincronizarCampanha = async (callback) => {
      try {
        await new CampanhaService().useSincronizarCampanha();
        callback();
      } catch (error) {
        console.error(error);
      }
    };

    sincronizarCampanha(() => {
      const campanhaService = new CampanhaService();
      const agendaService = new AgendaService();
      let service = new TemaService(campanhaService.campanhaCorrente());
      service.aplicarTema("HOME");
      if (agendaService.obterAgendaStorage()) {
        setMandatoryInterestParticipant(
          agendaService.obterAgendaStorage().mandatoryInterestParticipant,
        );
      } else {
        agendaService.agendaCorrente((_erro, agenda) => {
          if (agenda) {
            if (agenda.mandatoryInterestParticipant !== null) {
              setMandatoryInterestParticipant(
                agenda.mandatoryInterestParticipant,
              );
            }
          }
        });
      }
    });
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  agendaService.agendaCorrente((erro, agenda) => {});
  let daysOfEvent;
  let daysOfContent = campanha.daysOfContent;

  daysOfEvent = daysOfContent - mandatoryInterestParticipant;

  let beginDate;
  if (campanha && campanha.startDate) {
    let dtStart = campanha.startDate.split(" ");
    let dateStart = dtStart[0].split("/");
    let hourStart = dtStart[1].split(":");
    beginDate = new Date(
      dateStart[2],
      dateStart[1] - 1,
      dateStart[0],
      hourStart[0],
      hourStart[1],
      hourStart[2],
    );
  }

  if (!daysOfContent) {
    let dtEnd = campanha.endContentDate;
    if (!dtEnd) {
      dtEnd = campanha.endDate;
    }
    dtEnd = dtEnd.split(" ");
    let dateEnd = dtEnd[0].split("/");
    let hourEnd = dtEnd[1].split(":");
    let endDate = new Date(
      dateEnd[2],
      dateEnd[1] - 1,
      dateEnd[0],
      hourEnd[0],
      hourEnd[1],
      hourEnd[2],
    );

    let diff = endDate - beginDate;
    daysOfEvent = Math.ceil(diff / (1000 * 60 * 60 * 24)) + 1;
  }

  let dtBeginPreCampaign = campanha.preEventStartDate;
  if (!dtBeginPreCampaign) {
    dtBeginPreCampaign = campanha.launchDate;
  }
  let dtBeginPreCampaignSplit = dtBeginPreCampaign.split(" ");
  let dtBeginPreCampaignDate = dtBeginPreCampaignSplit[0].split("/");
  let dtBeginPreCampaignHour = dtBeginPreCampaignSplit[1].split(":");
  let dateStartPreCampaign = new Date(
    dtBeginPreCampaignDate[2],
    dtBeginPreCampaignDate[1] - 1,
    dtBeginPreCampaignDate[0],
    dtBeginPreCampaignHour[0],
    dtBeginPreCampaignHour[1],
    dtBeginPreCampaignHour[2],
  );

  let dtEndPreCampaign = campanha.preEventEndDate;
  if (!dtEndPreCampaign) {
    dtEndPreCampaign = campanha.startDate;
  }
  let dtEndPreCampaignSplit = dtEndPreCampaign.split(" ");
  let dtendPreCampaignDate = dtEndPreCampaignSplit[0].split("/");
  let dtEndPreCampaignHour = dtEndPreCampaignSplit[1].split(":");
  let dateEndPreCampaign = new Date(
    dtendPreCampaignDate[2],
    dtendPreCampaignDate[1] - 1,
    dtendPreCampaignDate[0],
    dtEndPreCampaignHour[0],
    dtEndPreCampaignHour[1],
    dtEndPreCampaignHour[2],
  );

  let now = new Date().getTime();

  let titleMessage = i18n.message(
    "interesse.selecioneInteresses.titulo",
    "SELECIONE SEUS INTERESSES",
  );
  let selectMessage =
    i18n.message(
      "interesse.selecioneAbaixo.subtitulo",
      "Selecione abaixo até",
    ) +
    " " +
    daysOfEvent +
    " " +
    i18n.message(
      "interesse.assuntosQueVoceMaisQuerSaber.subtitulo",
      "temas de interesse para personalizar a sua experiência.",
    );
  let canSelectInterets = true;

  let inicioSelecao =
    dtBeginPreCampaignDate[0] +
    "/" +
    dtBeginPreCampaignDate[1] +
    "/" +
    dtBeginPreCampaignDate[2] +
    " " +
    dtBeginPreCampaignHour[0] +
    ":" +
    dtBeginPreCampaignHour[1];

  if (requiredInterests === false) {
    if (dateStartPreCampaign.getTime() >= now) {
      selectMessage =
        i18n.message(
          "interesse.selecaoNaoIniciada.subtitulo",
          "Ainda não é possível selecionar interesses. A seleção inicia em " +
            inicioSelecao,
        ) + ".";
      canSelectInterets = false;
    } else if (dateEndPreCampaign.getTime() <= now) {
      selectMessage = i18n.message(
        "interesse.selecaoFinalizada.subtitulo",
        "O período de seleção de temas de interesses finalizou. Não será possível alterar os interesses selecionados.",
      );
      canSelectInterets = false;
    }
  }

  const callbackModal = () => {
    if (erro.isWrong === true) {
      setErro({ isWrong: false });
      navigate("/");
    }
  };

  return (
    <>
      {erro.isWrong === true ? (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}
      {isDesktop ? (
        <InteressesDesktop
          titleMessage={titleMessage}
          selectMessage={selectMessage}
          campanha={campanha}
          beginDate={beginDate}
          now={now}
          daysOfEvent={daysOfEvent}
          canSelectInterets={canSelectInterets}
          requiredInterests={requiredInterests}
        />
      ) : (
        <InteressesMobile
          titleMessage={titleMessage}
          selectMessage={selectMessage}
          campanha={campanha}
          beginDate={beginDate}
          now={now}
          daysOfEvent={daysOfEvent}
          canSelectInterets={canSelectInterets}
          requiredInterests={requiredInterests}
        />
      )}
    </>
  );
}

export default Interesses;
