import instance from "./Api";
import UsuarioService from "./UsuarioService";

export default class FatoFakeService {
  usuarioService = new UsuarioService();
  usuarioCorrente = this.usuarioService.usuarioCorrente();
  CHAVE_ACHIEVEMENTS = "achievements";

  responderQuestaoFF(body, callback) {
    instance
      .post(this.host() + "/api/v1/campaign/factOrFake/question/answer", body)
      .then((response) => {
        if (response.data.achievements?.length > 0) {
          localStorage.setItem(
            this.CHAVE_ACHIEVEMENTS + "-factOrFake",
            JSON.stringify(response.data.achievements),
          );
        }
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
