import instance from "./Api";
import CampanhaService from "./CampanhaService";
import UsuarioService from "./UsuarioService";
export default class FeedbackService {
  feedbackHabilitado() {
    let campanha = new CampanhaService().campanhaCorrente();
    if (campanha && campanha.feedbackDate) {
      const agora = new Date();
      // Dividir a data e a hora
      const [dataPart, horaPart] = campanha.feedbackDate.split(" ");

      // Dividir os componentes da data
      const [dia, mes, ano] = dataPart.split("/").map(Number);

      // Dividir os componentes da hora, se existir
      const [hora, minuto, segundo] = horaPart
        ? horaPart.split(":").map(Number)
        : [0, 0, 0];

      // Criar o objeto Date com todos os componentes
      const novaData = new Date(ano, mes - 1, dia, hora, minuto, segundo, 0);
      return novaData < agora;
    }
    return false;
  }

  feedbackRespondido(callback) {
    let usuarioService = new UsuarioService();
    let usuarioCorrente = usuarioService?.usuarioCorrente();
    if (usuarioCorrente) {
      let uuid = usuarioService?.getCodigoParticipante();
      if (!uuid) {
        console.error(
          "Codigo do participante não encontrado em FeedbackService.",
        );
      }
      instance
        .get(this.host() + "/api/v1/participant/" + uuid + "/feedback")
        .then((response) => {
          callback(null, response.data);
        })
        .catch((error) => {
          callback(error, null);
        });
    }
  }

  enviarFeedback(feedback, callback) {
    let uuid = new UsuarioService()?.getCodigoParticipante();
    feedback.participanteUuid = uuid;
    let body = {
      participanteUuid: uuid,
      relevancia: feedback.conteudoRelevante,
      recomendaria: feedback.recomendaria,
      participariaNovamente: feedback.participariaNovamente,
      message: feedback.mensagem,
    };
    instance
      .post(this.host() + "/api/v1/participant/" + uuid + "/feedback", body)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
