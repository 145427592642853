import styled from "styled-components";

export const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;

  .registerForm__fieldset--register {
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .registerForm__fieldset {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin: 0 21vw;
    .registerForm__fieldset {
      display: flex;
      flex-direction: row;
      gap: 10%;
      margin-bottom: 1rem;

      /* Aplica o tamanho de 50% nos inputs
      apenas dentro do formulário de registro */
      div {
        width: 50%;
      }
    }

    .registerForm__fieldset--register {
      padding-left: 15%;
      padding-right: 15%;
    }
  }
  /* #endregion DESKTOP */
`;

export const LoginRegisterStyle = styled.div`
  width: 100%;
  min-width: 100%;

  .LoginRegister__container {
    margin-top: 0;
    padding: 0em 1em;
    margin-bottom: 1em;
  }
`;

export const LoginStyle = styled.div`
  .loginStyle__form {
    width: 100%;
    label {
      margin-top: 0;
    }
  }

  .loginStyle__enterButton {
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }

  .loginStyle__register {
    display: flex;
    align-items: baseline;
    justify-content: ${(props) => {
      if (props.$loginComSenha) {
        return "space-between";
      } else {
        return "center";
      }
    }};
  }

  .loginStyle__2fa {
    text-align: center;
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }

  .login__2fa--error {
    color: #f14668;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .loginStyle__enterButton {
      margin-bottom: 2.5rem;
    }

    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  /* #endregion DESKTOP */
`;
