/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import AgendaService from "../../../services/AgendaService";
import PreSelecaoInteressesService from "../../../services/PreSelecaoInteressesService";
import UsuarioService from "../../../services/UsuarioService";
import PrimarioBtn from "../../buttonPWA2.0/PrimarioBtn";
import InteressesConfirmacaoModal from "../../weexModais/InteressesConfirmacaoModal";
import WeexModal from "../../weexModais/WeexModal";
import { InteresseFormStyle } from "./style";
import CampanhaService from "../../../services/CampanhaService";

const campanha = new CampanhaService();

const Interesse = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const preSelecaoInteressesService = new PreSelecaoInteressesService();
  const agendaService = new AgendaService();
  const i18n = useStore().getState().i18n;
  const [interesse, setInteresse] = useState([""]);

  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });
  const [salvando, setSalvando] = useState({
    isSaved: false,
    titulo: "",
    mensagem: "",
  });

  const [confirmando, setConfirmando] = useState({
    isConfirm: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });

  const usuarioService = new UsuarioService();
  const usuarioCorrente = usuarioService.usuarioCorrente();

  useEffect(() => {
    agendaService.agendaCorrente((erro, interesseJaSelecionado) => {
      if (interesseJaSelecionado) {
        let interesseEditado = interesseJaSelecionado.interests;
        setInteresse(interesseEditado);
        return;
      }
      if (erro) {
        setErro(erro);
        return;
      }
    });
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeInteresse = (event) => {
    if (props.canSelectInterets) {
      const { name, value, checked } = event.target;

      if (checked) {
        setInteresse([
          ...interesse,
          {
            name: name,
            uuid: value,
          },
        ]);
      } else {
        setInteresse(
          interesse.filter(function (val) {
            return val.uuid !== value;
          }),
        );
      }
    }
  };

  const confirmacaoDeEnvioDeInteresses = () => {
    setConfirmando({
      isConfirm: true,
      titulo: i18n.message(
        "interesse.selecaoObrigatoria.mensagemConfirmaTitulo",
        "Confirma seleção de interesses?",
      ),
      mensagem: i18n.message(
        "interesse.selecaoObrigatoria.mensagemConfirmaConteudo",
        "Você não poderá alterar mais tarde seus interesses. Deseja Continuar?",
      ),
    });
  };

  const voltarInteresse = () => {
    if (confirmando.isConfirm === true) {
      setConfirmando({ isConfirm: false });
    }
  };

  const submitInteresse = (event) => {
    event.preventDefault();

    if (!interesse.length) {
      setErro({
        isWrong: true,
        titulo: i18n.message(
          "interesse.erro.aoAdicionarInteresse.titulo",
          "Erro ao adicionar interesse",
        ),
        mensagem: i18n.message(
          "interesse.erro.quantidadeDeInteresses.mensagem",
          "Selecione pelo menos um tema de interesse para continuar.",
        ),
        voltar: true,
      });
      return;
    }

    if (interesse.length > props.daysOfEvent) {
      setErro({
        isWrong: true,
        titulo: i18n.message(
          "interesse.erro.aoAdicionarInteresse.titulo",
          "Erro ao adicionar interesse",
        ),
        mensagem:
          i18n.message(
            "interesse.erro.quantidadeDeInteresses.mensagem.part1",
            "Você deve selecionar no máximo ",
          ) +
          props.daysOfEvent +
          i18n.message(
            "interesse.erro.quantidadeDeInteresses.mensagem.part2",
            " temas de interesse para continuar.",
          ),
        voltar: true,
      });
      return;
    }
    preSelecaoInteressesService.selecaoDeInteresses(
      interesse,
      (erro, sucesso) => {
        if (erro) {
          if (
            erro?.response?.data?.code === "400" ||
            erro?.response?.data?.code === "BAD_REQUEST"
          ) {
            setErro({
              isWrong: true,
              titulo: i18n.message(
                "interesse.erro.aoAdicionarInteresse.titulo",
                "Erro ao adicionar interesse",
              ),
              mensagem: erro?.response?.data?.message,
              voltar: true,
            });
            return;
          }
          setErro({
            isWrong: true,
            titulo: i18n.message(
              "interesse.erro.aoAdicionarInteresse.titulo",
              "Erro ao adicionar interesse",
            ),
            mensagem:
              i18n.message(
                "interesse.erro.quantidadeDeInteresses.mensagem.part1",
                "Você deve selecionar no máximo ",
              ) +
              props.daysOfEvent +
              i18n.message(
                "interesse.erro.quantidadeDeInteresses.mensagem.part2",
                " temas de interesse para continuar.",
              ),
            voltar: true,
          });
          return;
        }
        if (sucesso) {
          setInteresse(sucesso);
          agendaService.atualizarInteresse(true);
          setErro({ isWrong: false });
          setSalvando((prevState) => ({
            ...prevState,
            isSaved: true,
            titulo: i18n.message(
              "interesse.sucesso.interesseAdicionado.titulo",
              "Interesses adicionados",
            ),
            mensagem: i18n.message(
              "interesse.sucesso.interessesSalvos.mensagem",
              "Os interesses foram salvos com sucesso",
            ),
          }));
          return;
        }
      },
    );
  };

  const callbackModal = () => {
    if (erro.isWrong === true) {
      setErro({ isWrong: false });
      navigate("/interests");
    }
  };

  const callbackModalSuccess = () => {
    if (salvando.isSaved === true) {
      setSalvando({ isSaved: false });
      contadorCampanha();
    }
  };

  const contadorCampanha = () => {
    campanha.contadorCampanha((erro, sucesso) => {
      if (erro) {
        console.error("Erro obter lista de dias habilitados");
        return;
      }
      if (sucesso) {
        if (sucesso !== null) {
          if (sucesso.length > 0) {
            dispatch({ type: "campanhaComecou", payload: true });
            navigate("/atividades");
            return;
          }
          dispatch({ type: "campanhaComecou", payload: false });
          navigate("/home");
          return;
        }
      }
    });
  };

  const osInteresses = props.campanha.map((i) => {
    let clicked = false;
    let isInteresseUsuario = false;
    if (i.units != null) {
      isInteresseUsuario = i.units.find((is) => is === usuarioCorrente.unit);
    }

    if (i.units === null || isInteresseUsuario) {
      return (
        <div className="container-interesse" key={i.uuid}>
          {interesse.forEach((e) => {
            if (i.uuid === e.uuid) {
              clicked = !clicked;
            }
          })}
          <label
            className="title-interesse"
            htmlFor={i.uuid}
            style={{ flexShrink: "10" }}
          >
            {i.name}
          </label>
          <label htmlFor={i.uuid} className="switch-interesse">
            <input
              id={i.uuid}
              className="checkbox-interesse"
              type="checkbox"
              name={i.name}
              value={i.uuid}
              checked={clicked}
              onChange={handleChangeInteresse}
            />
            <span
              className="slider-interesse round"
              style={{ width: "4rem" }}
            ></span>
          </label>
        </div>
      );
    } else {
      return null;
    }
  });

  const osInteressesDesabilitados = props.campanha.map((i) => {
    let clicked;

    return (
      <div className="container-interesse" key={i.uuid}>
        {interesse.forEach((e) => {
          if (i.uuid === e.uuid) {
            clicked = !clicked;
          }
        })}
        <label className="title-interesse" htmlFor={i.uuid}>
          {i.name}
        </label>
        <label className="switch-interesse">
          <input
            id={i.uuid}
            className="checkbox-interesse"
            type="checkbox"
            name={i.name}
            value={i.uuid}
            checked={clicked}
            disabled={!props.canSelectInterets}
          />
          <span className="slider-interesse round"></span>
        </label>
      </div>
    );
  });

  return (
    <>
      {confirmando.isConfirm === true ? (
        <InteressesConfirmacaoModal
          confirmarCallback={submitInteresse}
          cancelarCallback={voltarInteresse}
          titulo={confirmando.titulo}
          conteudo={confirmando.mensagem}
        />
      ) : null}
      {salvando.isSaved === true ? (
        <WeexModal
          fecharModalCallback={callbackModalSuccess}
          titulo={salvando.titulo}
          conteudo={salvando.mensagem}
        />
      ) : null}
      {erro.isWrong === true ? (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}
      <InteresseFormStyle>
        <form>
          <div className="interesse-container">
            {props.canSelectInterets ? osInteresses : osInteressesDesabilitados}
          </div>
        </form>
        {props.requiredInterests === true && props.beginDate <= props.now ? (
          <div
            style={{ marginBottom: "2rem" }}
            className="interesse-btn-container"
          >
            <PrimarioBtn
              nome={
                salvando.isSaved === false
                  ? i18n.message("interesse.salvar", "SALVAR")
                  : i18n.message("interesse.salvando", "SALVANDO...")
              }
              disabled={props.canSelectInterets ? false : true}
              funcao={confirmacaoDeEnvioDeInteresses}
              ariaStatusMessage={i18n.message(
                "aria.status.mensagemreutilizavel.botaosalvarclicado",
                "Botão salvar clicado.",
              )}
            />
          </div>
        ) : (
          <div
            style={{ marginBottom: "2rem" }}
            className="interesse-btn-container"
          >
            <PrimarioBtn
              nome={
                salvando.isSaved === false
                  ? i18n.message("interesse.salvar", "SALVAR")
                  : i18n.message("interesse.salvando", "SALVANDO...")
              }
              disabled={!props.canSelectInterets}
              funcao={submitInteresse}
              ariaStatusMessage={i18n.message(
                "aria.status.mensagemreutilizavel.botaosalvarclicado",
                "Botão salvar clicado.",
              )}
            />
          </div>
        )}
      </InteresseFormStyle>
    </>
  );
};

export default Interesse;
